.info-icon {
    border: 1px solid gray;
    border-radius: 50%;
    padding: 3px;
    width: 16px;
    height: 16px;
    display: flex;
    position: relative;
}

@media (min-width: 500px) {
    .info-icon {
        width: 18px;
        height: 18px;
    }
}

.info-icon:hover {
    background-color: #d9d9d9;
    border-color: #d9d9d9;
}

img {
    width: 100%;
    margin: 0;
}

.info-wrapper {
    display: flex;
    align-items: center;
    padding: 0 8px;
}

.info-icon .tooltip {
    position: absolute;
    text-align: center;
    bottom: -8px;
    left: 100%;
    background-color: gray;
    color: white;
    min-width: 220px;
    padding: 5px;
    border-radius: 8px;
    margin-left: 10px;
    display: none;
}

.info-icon .tooltip_info {
    bottom: -55px;
    left: 0;
}

.info-icon .tooltip_skip-charge {
    left: -60px;
    bottom: 25px;
}

.info-icon .tooltip_info-activate {
    min-width: 180px;
}

@media (max-width: 500px) {
    .info-icon .tooltip {
        font-size: 12px;
        min-width: 140px;
    }

    .info-icon .tooltip_deviceStatus {
        bottom: -25px;
        left: 7px;
    }

    .info-icon .tooltip_savings {
        bottom: -75px;
        left: -45px;
    }

    .info-icon .tooltip_info-activate {
        bottom: -70px;
        left: -35px;
    }

    .info-icon .tooltip_info {
        left: -25px;
        bottom: -70px;
    }

    .info-icon .tooltip_skip-charge {
        bottom: 10px;
        left: -50px;
    }

    .info-icon .tooltip_deviceStatus-lost,
    .info-icon .tooltip_hvac {
        left: -45px;
        bottom: -85px;
    }
}

@media (max-width: 320px) {
    .info-icon .tooltip_skip-charge,
    .info-icon .tooltip_info {
        left: -55px;
    }

    .info-icon .tooltip_info-activate {
        left: -80px;
    }

    .info-icon .tooltip_savings {
        left: -85px;
    }
}

.info-icon:hover .tooltip {
    opacity: 1;
    display: block;
}
