.custom-popup-content,
.edit-content {
    color: #0a0a0a;
    margin: auto;
    background: #ffffff;
    border: none;
    animation: zoom-in 0.5s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
    -webkit-animation: zoom-in 0.5s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
    text-align: center;
    border-radius: 10px;
    min-width: 40%;
    padding: 20px;
}

.custom-popup-overlay {
    background: rgba(0, 0, 0, 0.36);
}
