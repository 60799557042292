.notification-popup-content .close {
    cursor: pointer;
    position: absolute;
    padding: 2px 5px;
    line-height: 14px;
    right: 8px;
    top: 5px;
    font-size: 12px;
    opacity: 1;
    color: var(--text-color);
    outline: none;
    background: none;
    border: none;
}

.notification-text {
    font-size: 10px;
    margin: 0;
    padding: 20px 0 0;
    font-weight: 500;
    letter-spacing: 0.3px;
}

.notification-title {
    text-transform: uppercase;
}

@media (max-width: 500px) {
    .notification-popup,
    .popup-content.notification-popup-content {
        width: 60%;
    }
}
