.ChargeStation {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    background-color: #ffffff;
    border-radius: 10px;
    align-items: center;
    font-size: 18px;
    margin-bottom: 25px;
    box-shadow: 0px 0px 10px 1px #e3e1e1;
    padding: 30px 0;
}

.ChargeStation__titleContainer {
    display: flex;
    height: 100%;
    align-items: flex-end;
}

.ChargeStation__title {
    font-size: 28px;
    max-width: 300px;
    text-align: start;
    line-height: 28px;
}

.ChargeStation__ChargingStatus {
    background-color: #d4d0d0;
    border: 0px;
    border-radius: 0.3vh;
    padding: 0.4vh 0.5vw 0.4vh 0.5vw;
    font-size: 1vw;
    white-space: nowrap;
    margin: 1vw 0vw 0vw 0vw;
}

.ChargeStation__firstRow {
    display: flex;
    flex-direction: row;
    width: 90%;
    align-items: flex-end;
    justify-content: space-between;
    padding: 20px 0;
}

.ChargeStation__crossCard {
    position: absolute;
    right: 0;
    top: 0;
    margin-top: 0.4vw;
    margin-right: 0.8vw;
    font-size: 1.7vw;
    color: #888888;
    font-family: 'fontAwesome';
    cursor: pointer;
    transition: 0.4s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
    -webkit-text-stroke: 1px white;
}

.ChargeStation__crossCard:hover {
    color: #545454;
    font-size: 1.9vw;
    margin-top: 0.3vw;
    margin-right: 0.7vw;
}

.ChargeStation__secondRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 90%;
    justify-content: flex-start;
    padding: 15px 0 0;
    border-top: 1px solid #ededf5;
}

.ChargeStation__secondRowTitle {
    white-space: nowrap;
    font-size: 18px;
    margin-right: 10px;
}

.ChargeStation__Filter select {
    background-color: #f4f4f4;
    color: #000000;
    width: 90px;
    text-align: center;
    padding: 5px;
    height: auto;
    border: none;
    font-size: 16px;
    -webkit-appearance: none;
    appearance: none;
    outline: none;
    border-radius: 10px;
    touch-action: manipulation;
    cursor: pointer;
}

@media (max-width: 500px) {
    .ChargeStation__crossCard {
        position: absolute;
        right: 0;
        top: 0;
        margin-top: 1vw;
        margin-right: 2vw;
        font-size: 5vw;
        color: #888888;
        font-family: 'fontAwesome';
    }

    .ChargeStation__crossCard:hover {
        color: #545454;
        font-size: 6vw;
        margin-top: 1vw;
        margin-right: 2vw;
    }

    .ChargeStation {
        position: relative;
        display: flex;
        flex-direction: column;
        height: auto;
        background-color: #fbfbfb;
        border-radius: 10px;
        align-items: center;
        width: 100%;
        font-size: 3vw;
        padding: 5vw 0vw;
        margin-bottom: 10px;
        border: 1px solid #ededf5;
    }

    .ChargeStation__title {
        font-size: 18px;
        margin-top: 2vw;
    }

    .ChargeStation__ChargingStatus {
        background-color: #d4d0d0;
        border: 0px;
        border-radius: 0.3vh;
        padding: 0.5vh 2vw 0.5vh 2vw;
        font-size: 2.5vw;
        white-space: nowrap;
        margin: 2vw 0vw 0vw 0vw;
    }

    .ChargeStation__firstRow {
        display: flex;
        flex-direction: row;
        width: 90%;
        align-items: flex-end;
        justify-content: space-between;
        padding: 20px 0;
    }

    .ChargeStation__secondRow {
        display: flex;
        flex-direction: row;
        width: 90%;
        align-items: center;
        justify-content: flex-start;
    }

    .ChargeStation__secondRowTitle {
        white-space: nowrap;
        font-size: 14px;
    }

    .ChargeStation__Filter select {
        background-color: #f4f4f4;
        width: 70px;
        text-align: center;
        height: auto;
        border: none;
        font-size: 14px;
        -webkit-appearance: none;
        appearance: none;
        outline: none;
        touch-action: manipulation;
        cursor: pointer;
    }
}

@media (min-width: 500px) and (max-width: 768px) {
    .ChargeStation__title {
        font-size: 20px;
    }

    .ChargeStation__secondRowTitle {
        font-size: 16px;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .ChargeStation__title {
        font-size: 24px;
    }
}

.settings-wrap {
    padding: 20px 0;
    border-top: 1px solid #ededf5;
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
