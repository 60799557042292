.Ev__ProgressBar {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.Ev__ProgressBarContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.ev__percent {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
    font-weight: 700;
    font-size: 14px;
}

@media (min-width: 991px) {
    .ev__percent {
        font-size: 18px;
    }
}

.Ev__crossCard {
    position: absolute;
    right: 10px;
    top: 5px;
    font-size: 1.7vw;
    color: #888888;
    font-family: 'fontAwesome';
    cursor: pointer;
    transition: 0.4s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
    -webkit-text-stroke: 1px white;
}

.Ev__crossCard:hover {
    color: #545454;
    transform: scale(1.1);
}

.Ev {
    position: relative;
    display: flex;
    flex-direction: column;
    height: auto;
    background-color: #ffffff;
    border-radius: 10px;
    align-items: center;
    font-size: 1.5vw;
    margin-bottom: 25px;
    box-shadow: 0px 0px 10px 1px #e3e1e1;
    padding: 10px 0;
    width: 100%;
}

.ev__data-wrapper {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-evenly;
    align-items: center;
    gap: 10px;
    margin-top: 25px;
}

.Ev__title {
    font-size: 18px;
    margin-bottom: 10px;
}

@media (min-width: 991px) {
    .Ev__title {
        font-size: 28px;
    }

    .ev__data-wrapper {
        gap: 0;
    }
}

.Ev__firstRow {
    /* display: flex; */
    /* flex-direction: row; */
    width: 90%;
    /* align-items: flex-end; */
    /* justify-content: space-between; */
    border-bottom: 1px solid #ededf5;
    padding: 40px 15px 20px;
}

.Ev__ChargingStatus {
    background-color: #d4d0d0;
    border: 0px;
    border-radius: 8px;
    padding: 5px 10px;
    font-size: 14px;
    line-height: 12px;
    white-space: normal;
}

.Ev__secondRow {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    width: 90%;
    border-bottom: 1px solid #ededf5;
    padding: 20px 0;
    gap: 10px;
}

.Ev__secondRowFirst {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    min-width: 420px;
}

.Ev__secondRowSecond {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.Ev__secondRowTitle {
    white-space: break-spaces;
    font-size: 18px;
    margin-right: 5px;
    display: flex;
    text-align: start;
}

.Ev__Filter select {
    background-color: #f4f4f4;
    color: #000000;
    width: 110px;
    text-align: center;
    padding: 5px;
    height: auto;
    border: none;
    font-size: 16px;
    -webkit-appearance: none;
    appearance: none;
    outline: none;
    border-radius: 10px;
    touch-action: manipulation;
    cursor: pointer;
}

.Ev__thirdRow {
    display: flex;
    flex-direction: row;
    width: 420px;
    height: 120px;
    position: relative;
    align-items: center;
    justify-content: space-between;
    gap: 180px;
    padding: 0;
}

.Ev__thirdRowTitle {
    white-space: normal;
    font-size: 18px;
}

.Ev__pieChartWrapper {
    overflow: hidden;
    position: absolute;
    left: 55%;
    transform: translateX(-50%);
}

.Ev__pieChartLegends {
    text-align: left;
    padding-right: 0.5vw;
}

.Ev__pieChartLegendsText {
    font-size: 18px;
}

.Ev__pieChartLegendHomeBullet {
    content: '\A';
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #ee801c;
    display: inline-block;
    margin: 0px 10px 0px 0px;
    position: relative;
    bottom: 2px;
}

.Ev__pieChartLegendOtherBullet {
    content: '\A';
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #d4cfc9;
    display: inline-block;
    margin: 0px 10px 0px 0px;
    position: relative;
    bottom: 2px;
}

.Ev__pieChartLegends div {
    display: flex;
    align-items: center;
}

.DeviceBase__chargingTitle {
    color: #000000;
}

@media (max-width: 500px) {
    .MyDevices::-webkit-scrollbar {
        width: 0;
        background: transparent;
    }

    .Ev {
        position: relative;
        display: flex;
        flex-direction: column;
        height: auto;
        background-color: #fbfbfb;
        border-radius: 10px;
        align-items: center;
        width: 100%;
        font-size: 3vw;
        border: 1px solid #ededf5;
        margin-bottom: 10px;
    }

    .Ev__crossCard {
        position: absolute;
        right: 0;
        top: 0;
        margin-top: 1vw;
        margin-right: 2vw;
        font-size: 5vw;
        color: #888888;
        font-family: 'fontAwesome';
    }

    .Ev__crossCard:hover {
        color: #545454;
        font-size: 6vw;
        margin-top: 1vw;
        margin-right: 2vw;
    }

    .Ev__ChargingStatus {
        background-color: #d4d0d0;
        border: 0px;
        border-radius: 8px;
        white-space: nowrap;
    }

    .Ev__secondRow {
        padding: 20px 0;
    }

    .Ev__secondRowFirst {
        justify-content: space-between;
    }

    .Ev__secondRowTitle {
        font-size: 14px;
    }

    .Ev__Filter select {
        background-color: #f4f4f4;
        text-align: center;
        width: 80px;
        height: auto;
        border: none;
        font-size: 12px;
        -webkit-appearance: none;
        appearance: none;
        outline: none;
        touch-action: manipulation;
        cursor: pointer;
    }

    .Ev__thirdRow {
        gap: none;
        justify-content: space-between;
    }

    .Ev__thirdRowTitle {
        white-space: normal;
    }

    .Ev__pieChartLegends {
        text-align: left;
        padding-right: 1vw;
    }

    .DeviceBase__chargingTitle {
        font-size: 12px;
    }

    .Ev__pieChartLegendOtherBullet,
    .Ev__pieChartLegendHomeBullet {
        width: 6px;
        height: 6px;
        bottom: 1px;
    }
}

@media (min-width: 991px) {
    .Ev__thirdRow {
        min-height: 150px;
    }
}

@media (min-width: 500px) and (max-width: 768px) {
    .MyDevices__container {
        width: 100%;
    }

    .Ev__pieChartLegendsText,
    .Ev__secondRowTitle,
    .Ev__thirdRowTitle,
    .Ev__pieChartLegends span,
    .Ev__Filter select {
        font-size: 16px;
    }

    .Ev__ChargingStatus {
        font-size: 14px;
    }

    .DeviceBase__chargingTitle {
        font-size: 13px;
    }

    .Ev__pieChartLegends div:nth-child(1)::before {
        width: 8px;
        height: 8px;
    }

    .Ev__pieChartLegends div:nth-child(2)::before {
        width: 8px;
        height: 8px;
    }

    .Ev__pieChartLegendOtherBullet,
    .Ev__pieChartLegendHomeBullet {
        width: 8px;
        height: 8px;
        bottom: 1px;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .MyDevices__container {
        width: 80%;
    }

    .DeviceBase__chargingTitle {
        font-size: 16px;
    }

    .Ev__secondRowTitle,
    .Ev__thirdRowTitle,
    .Ev__pieChartLegendsText,
    .Ev__pieChartLegends span,
    .Ev__Filter select {
        font-size: 18px;
    }

    .Ev__ChargingStatus {
        font-size: 16px;
    }

    .Ev__pieChartLegendOtherBullet,
    .Ev__pieChartLegendHomeBullet {
        width: 8px;
        height: 8px;
    }
}

.ev-button {
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: center;
    align-items: center;
    padding: 10px 0 0;
}

.button {
    border: none;
    padding: 5px;
    height: 30px;
    border-radius: 10px;
    background-color: #f4f4f4;
    color: black;
    text-align: center;
    font-size: 16px;
    line-height: 18px;
    font-weight: 400;
    letter-spacing: 0.5px;
    width: 350px;
    transition: color, background-color, border, 0.2s ease-in-out;
}

.details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 15px;
}

.range {
    font-size: 18px;
    font-weight: 600;
    padding: 0 10px 0;
}

.sub-detail {
    font-size: 16px;
    line-height: 16px;
    font-weight: normal;
    padding: 0 10px 0;
    color: #b0aeae;
}

.detail-text {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 5px 0 0;
}

.detail-text .DeviceBase__smartCharging {
    padding: 0 10px 0;
    align-items: flex-start;
}

.detail-text .DeviceBase__chargingTitle {
    font-size: 16px;
    line-height: 16px;
    font-weight: normal;
    color: #b0aeae;
    padding-top: 5px;
}

.charging-status {
    width: 90%;
    border-left: 1px solid #d4d0d0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 5px 0 0;
    margin-left: 70px;
}

.bold-text:disabled {
    opacity: 0.6 !important;
}

@media (max-width: 500px) {
    .skip-charging .DeviceBase__smartCharging {
        width: 80px !important;
    }

    .skip-charging .DeviceBase__btnContainer .DeviceBase__btnLabel {
        width: 50px !important;
        height: 20px !important;
    }

    .skip-charging .DeviceBase__btnContainer .DeviceBaseSmartCharging:checked + .DeviceBase__btnLabel::before {
        transform: translateX(28px) !important;
    }

    .detail,
    .range {
        font-size: 14px;
    }

    .sub-detail {
        font-size: 12px;
    }

    .Ev__secondRowTitle,
    .Ev__pieChartLegends div,
    .Ev__pieChartLegendsText,
    .Ev__thirdRowTitle {
        font-size: 14px;
    }

    .Ev__secondRowFirst {
        min-width: 280px;
    }

    .button {
        width: 250px;
    }

    .Ev__thirdRow {
        gap: 70px;
        width: 300px;
    }

    .detail-text .DeviceBase__chargingTitle {
        font-size: 12px;
    }

    .Ev__ChargingStatus {
        font-size: 12px;
        white-space: normal;
    }

    .Ev__pieChartLegends {
        word-break: break-word;
        max-width: 75px;
    }
}

.skip-charging {
    padding-top: 20px;
    margin-top: 10px;
    align-items: center;
    border-top: 1px solid #ededf5;
}

.skip-charging .DeviceBase__smartCharging {
    width: 110px;
    display: flex;
    justify-content: center;
}

.skip-charging .DeviceBase__btnContainer .DeviceBase__btnLabel {
    width: 70px;
}

.skip-charging .DeviceBase__btnContainer .DeviceBaseSmartCharging:checked + .DeviceBase__btnLabel::before {
    transform: translateX(48px);
}

.Ev__secondRowTitle p {
    margin-bottom: 0 !important;
}

@media (max-width: 350px) {
    .Ev__secondRowFirst,
    .Ev__thirdRow {
        min-width: auto;
        width: 250px;
    }
}

@media (max-width: 320px) {
    .Ev__secondRowTitle p {
        max-width: 150px;
        white-space: break-spaces;
        text-align-last: left;
        margin-bottom: 0 !important;
    }

    .Ev__secondRowFirst {
        width: 215px;
    }

    .Ev__thirdRow {
        width: 230px;
    }

    .Ev__ChargingStatus {
        font-size: 10px;
    }
}

#smart-charging-ev {
    flex-direction: column-reverse !important;
}

.ev__range {
    padding-top: 5px;
    width: 110px;
    position: relative;
}

input[type='range'] {
    width: 100%;
    -webkit-appearance: none;
    appearance: none;
    background: linear-gradient(to right, #ee801c 0%, #ee801c 10%, #b0aeae 10%, #b0aeae 100%);
    border-radius: 8px;
    height: 7px;
    outline: none;
    transition: background 450ms ease-in;
}

input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    cursor: pointer;
    background: #ee801c;
}

input[type='range']:focus {
    outline: none;
}

.ev__percentage {
    position: absolute;
    top: -18px;
    text-align: center;
    font-size: 16px;
}
