.__closeBtnContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: absolute;
    width: 100%;
    margin-top: 1vw;
    padding: 0vw 1vw;
}

.__closeBtnContainer a {
    text-decoration: none;
    -webkit-text-stroke: 0.5px white;
    color: #000000;
}

.__closeBtnContainer button {
    font-family: 'fontAwesome';
    background-color: #ffffff;
    border: 0px;
    width: 2vw;
    height: 2vw;
    font-size: 1.4vw;
    box-shadow: 0px 4px 4px 0px #00000008;
    border-radius: 5px;
    color: #888888;
    cursor: pointer;
    transition: 0.8s cubic-bezier(0.075, 0.82, 0.165, 1) 0.02s;
    -webkit-text-stroke: 0.5px white;
}

.__closeBtnContainer button:hover {
    font-size: 1.5vw;
    width: 2.5vw;
    height: 2.5vw;
    color: #6c6c6c;
}

@media (max-width: 500px) {
    .__closeBtnContainer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        position: absolute;
        width: 100%;
        margin-top: 4vh;
        padding: 0vw 1vw;
    }

    .__closeBtnContainer button {
        font-family: 'fontAwesome';
        border: 0px;
        background-color: #ffffff;
        width: 5vw;
        height: 5vw;
        font-size: 5vw;
        border-radius: 5px;
        color: #888888;
        box-shadow: 0px 0px 0px 0px #00000008;
        cursor: pointer;
        transition: 0.8s cubic-bezier(0.075, 0.82, 0.165, 1) 0.02s;
        -webkit-text-stroke: 0.5px white;
    }

    .__closeBtnContainer button:hover {
        width: 5vw;
        height: 5vw;
        font-size: 6vw;
        color: #6c6c6c;
    }
}

.AddDevices {
    display: flex;
    justify-content: center;
    position: relative;
    width: 100%;
    text-align: center;
    font-size: 2vh;
}

.AddDevices__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 25px 0px;
    width: 100%;
}

.AddDevices__Wrapper {
    width: 100%;
}

.AddDevices__container h3 {
    line-height: 1.2;
    margin-bottom: 30px;
}

.AddDevices__Categories {
    width: 100%;
    margin-bottom: 40px;
    grid-template-columns: repeat(2, 1fr);
    display: grid;
    column-gap: 18px;
    row-gap: 10px;
}

.AddDevices__Category:nth-child(odd):last-child {
    grid-column: 1 / span 2;
    width: 100%;
}

.AddDevices__Category {
    font-size: 13px;
    padding: 10px;
    background-color: #ffffff;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    box-shadow: 0px 0px 5px 2px #e3e1e1;
}

@media (min-width: 768px) {
    .AddDevices__Category {
        font-size: 15px;
    }
}

@media (min-width: 991px) {
    .AddDevices__Category {
        font-size: 16px;
    }
}

.AddDevices__Category:hover {
    background-color: #fbfbfb;
    transform: scale(1.05);
}

.AddDevices__catagoryActive {
    background-color: #fbfbfb;
    box-shadow: 0px 0px 5px 2px #a3a3a3;
    transform: scale(1.05);
}

.Devices__row {
    justify-self: center;
    width: 100%;
    display: grid;
    grid-auto-rows: 15vh;
    grid-gap: 20px;
    grid-template-columns: repeat(3, 1fr);
    padding-bottom: 25px;
}

.Devices__item {
    display: flex;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 1px #e3e1e1;
    transition: 0.8s cubic-bezier(0.075, 0.82, 0.165, 1) 0.02s;
}

.Devices__item:hover {
    cursor: pointer;
    transform: scale(1.1);
}

.Devices__itemContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin: auto;
}

.Devices__itemContent h1 {
    font-size: 20px;
    color: #868686;
    margin-bottom: 0px;
}

.Devices__itemContent:hover h1 {
    color: #000;
}

.Devices__itemImg {
    width: 100%;
    height: 40px;
    margin-bottom: 6px;
    object-fit: contain;
}

@media (min-width: 500px) {
    .Devices__itemImg {
        height: 50px;
    }
}

@media (min-width: 768px) {
    .Devices__itemImg {
        height: 55px;
        margin-bottom: 10px;
    }
}

@media (min-width: 991px) {
    .Devices__itemImg {
        height: 60px;
    }
}

@media (max-width: 1024px) {
    .Devices__itemContent {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        margin: auto;
    }

    .Devices__itemContent h1 {
        font-size: 16px;
        color: #868686;
    }
}

@media (max-width: 768px) {
    .AddDevices__container h3 {
        font-size: 2vw;
    }

    .AddDevices__Categories {
        width: 43vw;
    }

    .AddDevices__catagoryActive {
        background-color: #f0f0f0;
    }

    .AddDevices h3 {
        font-size: 2vh;
    }

    .Devices__row {
        justify-self: center;
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }

    .Devices__itemContent {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        margin: auto;
    }

    .Devices__itemContent h1 {
        font-size: 16px;
        color: #868686;
    }
}

@media (max-width: 500px) {
    .AddDevices {
        background-color: #ffffff;
        border: 0px;
    }

    .AddDevices::-webkit-scrollbar {
        width: 0;
        background: transparent;
    }

    .AddDevices__container {
        width: 85vw;
    }

    .AddDevices__Categories {
        width: 85vw;
        margin-bottom: 25px;
        column-gap: 10px;
    }

    .AddDevices__container h3 {
        line-height: 1.3;
        margin-bottom: 15px;
        font-size: 22px;
    }

    .AddDevices__catagoryActive {
        background-color: #f0f0f0;
    }

    .Devices__row {
        display: grid;
        grid-template-columns: repeat(2, 48.33%);
        grid-auto-rows: 15vh;
        grid-gap: 3%;
        row-gap: 10px;
        justify-content: center;
    }

    .Devices__item {
        display: flex;
        background-color: #ffffff;
        border-radius: 10px;
        border: 0.1px solid #9fa1b34d;
        box-shadow: 0px;
    }

    .Devices__item:hover {
        cursor: pointer;
        transform: scale(1.1);
    }

    .Devices__itemContent {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        margin: auto;
        font-size: 0.8vw;
    }

    .Devices__itemContent h1 {
        font-size: 16px;
        color: #868686;
    }

    .Devices__itemContent:hover h1 {
        color: #000;
    }

    .AddDevices__Wrapper {
        width: 100%;
        padding: 0;
    }
}

@media (min-width: 500px) and (max-width: 768px) {
    .AddDevices__Categories {
        width: 100%;
    }

    .AddDevices__Category {
        font-size: 14px;
    }

    .AddDevices__Wrapper {
        width: 100%;
    }

    .AddDevices__container {
        width: 100%;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .AddDevices__Wrapper {
        width: 80%;
    }
}
