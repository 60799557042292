:root {
    --dot-diameter: 170px;
    --mobile-dot-diameter: 130px;
    --circle-border-width: 3px;
    --mobile-circle-border-width: 2px;
}

.circle-container {
    width: var(--mobile-dot-diameter);
    height: var(--mobile-dot-diameter);
    transform: rotate(-90deg);
}

.circle-bg {
    fill: none;
    stroke-width: var(--mobile-circle-border-width);
}

.circle__progress {
    fill: none;
    stroke-linecap: round;
    stroke-dasharray: 100 100;
    stroke-linecap: round;
    stroke-width: var(--mobile-circle-border-width);
}

@media (min-width: 991px) {
    .circle-container {
        width: var(--dot-diameter);
        height: var(--dot-diameter);
    }

    .circle-bg {
        stroke-width: var(--circle-border-width);
    }

    .circle__progress {
        stroke-width: var(--circle-border-width);
    }
}
