.wrapper {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-height: 600px;
}

.title {
    font-weight: 600;
    font-size: 26px;
    text-align: center;
    margin-bottom: 25px;
}

.image-wrapper {
    text-align: center;
    padding: 20px;
}

.images {
    display: inline-flex;
    align-items: center;
    gap: 10px;
    vertical-align: top;
}

.emulate-img {
    height: 75px;
    width: 75px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #e8eff7;
    border-radius: 8px;
    padding: 6px;
}

.logo img {
    transform: scale(0.8);
}

.horizontal-line {
    background-color: #dbe3eb;
    border-radius: 4;
    width: 24px;
    height: 3px;
    position: relative;
    overflow: hidden;
    animation-fill-mode: forwards;
}

.horizontal-line:before {
    content: '';
    background-color: #c8d3de;
    width: 100%;
    height: 100%;
}

.message-wrapper {
    color: #3b4755;
    background-color: #fff;
    border-radius: 8px;
    padding: 16px;
    text-align: center;
}

.message {
    font-size: 20px;
    line-height: 22px;
    margin-bottom: 28px;
    font-weight: 600;
}

.message-wrapper p {
    font-size: 16px;
    text-align: center;
}

@media (max-width: 500px) {
    .message {
        font-size: 18px;
    }
}

em {
    font-weight: 600;
}

.continue-btn {
    font-weight: 400;
    color: black;
    background-color: #d4d0d0;
    font-size: 16px;
    letter-spacing: 1px;
    border-radius: 6px;
    border: none;
    padding-inline: 20px;
    height: 40px;
    display: inline-flex;
    align-items: center;
    align-self: center;
    text-decoration: none;
}

.info-wrap {
    display: flex;
    justify-content: center;
}

.information-ico img {
    height: 20px;
    width: 20px;
}

.information-ico {
    height: 34px;
    width: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    display: flex;
    justify-content: center;
    margin-bottom: 16px;
    border: 1px solid gray;
    border-radius: 50%;
    padding: 3px;
}

@media (max-width: 320px) {
    .title {
        font-size: 16px;
    }

    .message-wrapper,
    .image-wrapper {
        margin-bottom: 0;
    }

    .message-wrapper p {
        font-size: 14px;
    }

    .continue-btn {
        font-size: 14px;
        padding-inline: 15px;
        height: 30px;
    }
}

@media (max-height: 550px) {
    .image-wrapper {
        padding: 0 20px 20px;
    }

    .message-wrapper {
        padding: 0 16px;
    }
}
