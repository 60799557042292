*,
::after,
::before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.Faq {
    display: flex;
    justify-content: center;
    position: relative;
    width: 100%;
    text-align: center;
    font-size: 2vh;
}

.Faq .section {
    margin: 0 auto;
    border: 1px solid red;
    background-color: #f9f9f9;
}

.Faq__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 25px 0px;
    width: 100%;
}

.Faq__container h3 {
    line-height: 1.2;
    margin-bottom: 25px;
}

.Faq__question {
    width: 100%;
}

.Faq__question h4 {
    text-transform: none;
    line-height: 1.5;
    margin-bottom: 0;
    cursor: pointer;
    font-size: 16px;
}

@media (min-width: 768px) {
    .Faq__question h4 {
        font-size: 18px;
    }
}

@media (min-width: 991px) {
    .Faq__question h4 {
        font-size: 20px;
    }
}

.Faq__question p {
    color: hsl(209, 34%, 30%);
    margin-bottom: 0;
    margin-top: 0.5rem;
    width: 100%;
}

.Faq__question header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border: 0.1px solid #9fa1b34d;
    border-radius: 20px;
    cursor: pointer;
    background-color: #fbfbfb;
    text-align: left;
    width: 100%;
    box-shadow: 0px 0px 10px 1px #e3e1e1;
}

.Faq__AnswerHidden {
    max-height: 0px;
    margin-bottom: 12.4px;
    transition: 1.5s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
}

.Faq__AnswerShow {
    margin-top: 10px;
    max-height: 500px;
    border: 0.1px solid #9fa1b34d;
    box-shadow: 0px 0px 10px 1px #e3e1e1;
    padding: 20px;
    border-radius: 20px;
    background-color: #f5f5f5;
    white-space: pre-wrap;
    word-wrap: break-word;
    margin-bottom: 1rem;
    width: 100%;
    overflow: hidden;
    transition: max-height 0.5s ease-out 0s;
}

.Faq__infoContainer {
    text-align: left;
    font-size: 16px;
    margin-bottom: 0 !important;
}

.Faq__questionBtn {
    background: transparent;
    border-color: transparent;
    width: 1vw;
    height: auto;
    color: #888888;
    cursor: pointer;
    margin-left: auto;
}

.Faq__questionBtn span {
    font-family: 'fontAwesome';
}

@media (max-width: 768px) {
    .Faq__question {
        width: 100%;
    }

    .Faq__infoContainer {
        font-size: 12px;
    }

    .Faq__question h4 {
        font-size: 14px;
    }
}

@media (max-width: 500px) {
    .Faq::-webkit-scrollbar {
        width: 0;
        background: transparent;
    }

    .Faq__container {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 25px 0px;
        width: 100%;
    }

    .Faq__container h3 {
        line-height: 1.3;
        margin-bottom: 15px;
        font-size: 3vh;
    }

    .Faq__question {
        width: 85vw;
        font-size: 3vw;
    }

    .Faq__AnswerShow {
        width: 85vw;
    }

    .Faq__question h4 {
        text-transform: none;
        line-height: 1.5;
        margin-bottom: 0;
        cursor: pointer;
        text-align: left;
    }

    .Faq__questionBtn {
        height: auto;
        margin-right: 10px;
        background: transparent;
        border-color: transparent;
        font-size: 4vw;
        color: #888888;
        cursor: pointer;
        margin-left: auto;
    }
}
