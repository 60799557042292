*,
*::before,
*::after {
    box-sizing: border-box;
}

.LayoutContainer {
    background-color: #ffffff;
}

.LayoutNavbar {
    width: 100%;
    grid-area: navbar;
    position: relative;
    z-index: 3;
}

.LayoutBody {
    max-width: 570px;
    width: 100%;
    padding: 0 15px;
    margin: 0 auto;
}

::-webkit-scrollbar {
    width: 8px;
    background-color: #ffffff;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #a9a9a9c6;
}

::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #ffffff;
}

@media (max-width: 500px) {
    .LayoutNavbar {
        grid-area: navbar;
        position: fixed;
        bottom: 0;
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: flex-end;
        z-index: 9;
        max-height: 70px;
    }

    .LayoutContainer .LayoutNavbar ul {
        margin-bottom: 0 !important;
    }

    .LayoutBody {
        grid-area: container;
        padding-bottom: 70px;
        overflow-y: scroll;
        overflow-x: hidden;
    }
}
