.battery-shape:after {
    content: ' ';
    left: 50%;
    transform: translate(-50%, -50%);
    height: 5px;
    width: 15px;
    position: absolute;
    background: black;
    border-radius: 2px 2px 0 0;
}

.battery {
    position: relative;
    border-radius: 5px;
    overflow: hidden;
    border: 1.5px solid black;
    width: 35%;
    padding: 20px;
    height: 95px;
}

.battery-fill {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
}
