.Hvac {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    background-color: #ffffff;
    border-radius: 10px;
    align-items: center;
    font-size: 1.5vw;
    margin-bottom: 25px;
    box-shadow: 0px 0px 10px 1px #e3e1e1;
    padding: 10px 0;
}

.Hvac__crossCard {
    position: absolute;
    right: 10px;
    top: 5px;
    font-size: 1.7vw;
    color: #888888;
    font-family: 'fontAwesome';
    cursor: pointer;
    transition: 0.4s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
    -webkit-text-stroke: 1px white;
}

.Hvac__crossCard:hover {
    color: #545454;
    transform: scale(1.1);
}

.Hvac__titleContainer {
    display: flex;
    height: 100%;
    align-items: flex-end;
    text-align: start;
}

.Hvac__title {
    font-size: 28px;
    line-height: 28px;
    max-width: 250px;
    text-align: start;
}

.Hvac__ChargingStatus {
    background-color: #d4d0d0;
    border: 0px;
    border-radius: 0.3vh;
    padding: 0.4vh 0.5vw 0.4vh 0.5vw;
    font-size: 1vw;
    white-space: nowrap;
    margin: 1vw 0vw 0vw 0vw;
}

.Hvac__firstRow {
    display: flex;
    flex-direction: row;
    width: 90%;
    align-items: flex-end;
    justify-content: space-between;
    border-bottom: 1px solid #ededf5;
    padding: 40px 0 15px;
}

.Hvac__secondRow {
    display: flex;
    flex-direction: row;
    width: 90%;
    align-items: flex-end;
    justify-content: space-between;
    height: auto;
    padding: 20px 0;
}

.Hvac__subTitles {
    white-space: nowrap;
    font-size: 13.5px;
    color: #8a8a8a;
    font-weight: 500;
}

.Hvac__Temperature {
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: flex-start;
    justify-content: flex-start;
}

.Hvac__TemperatureC {
    font-size: 20px;
}

.Hvac__Celsius {
    font-size: 21px;
}

.Hvac__HotWater {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    justify-content: space-between;
    opacity: 0.4;
}

.Hvac__HotWaterBtnContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    align-content: center;
    background-color: #f4f4f4;
    position: relative;
    border-radius: 0.5vw;
    width: 50%;
    height: 40%;
    max-height: 40%;
    padding: 19% 8%;
    margin-top: auto;
    margin-bottom: 0.4vw;
}

.Hvac__HotWaterBtn {
    height: auto;
    background-color: #f4f4f4;
    border: 0px;
    outline: none;
    text-align: center;
    vertical-align: center;
    cursor: pointer;
}

.Hvac__BoostImg {
    width: auto;
    height: auto;
    margin-bottom: -0.6vh;
    min-height: 1vw;
    max-height: 1.8vw;
}

.Hvac__HotWaterBtn:hover .Hvac__BoostImg {
    width: auto;
    height: auto;
    margin-bottom: -0.6vh;
    min-height: 1vw;
    max-height: 2.1vw;
}

@media (max-width: 500px) {
    .Hvac__crossCard {
        position: absolute;
        right: 0;
        top: 0;
        margin-top: 1vw;
        margin-right: 2vw;
        font-size: 5vw;
        color: #888888;
        font-family: 'fontAwesome';
    }

    .Hvac__crossCard:hover {
        color: #545454;
        font-size: 6vw;
        margin-top: 1vw;
        margin-right: 2vw;
    }

    .Hvac {
        position: relative;
        display: flex;
        flex-direction: column;
        height: auto;
        background-color: #fbfbfb;
        border-radius: 10px;
        align-items: center;
        width: 100%;
        margin-bottom: 10px;
        border: 1px solid #ededf5;
    }

    .Hvac__title {
        font-size: 18px;
    }

    .Hvac__ChargingStatus {
        background-color: #d4d0d0;
        border: 0px;
        border-radius: 0.3vh;
        padding: 0.5vh 2vw 0.5vh 2vw;
        font-size: 2.5vw;
        white-space: nowrap;
        margin: 2vw 0vw 0vw 0vw;
    }

    .Hvac__firstRow {
        display: flex;
        flex-direction: row;
        width: 90%;
        align-items: flex-end;
        justify-content: space-between;
        padding: 20px 0px 15px 0px;
    }

    .Hvac__secondRow {
        display: flex;
        flex-direction: row;
        width: 90%;
        align-items: flex-end;
        justify-content: space-between;
        padding: 20px 0;
    }

    .Hvac__chargingTitle {
        color: #999999;
        white-space: nowrap;
        font-size: 3vw;
        margin-bottom: 0.1vw;
    }

    .Hvac__subTitles {
        font-size: 1.5vh;
        font-weight: 500;
    }

    .Hvac__TemperatureC {
        font-size: 20px;
    }

    .Hvac__HotWaterBtnContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        align-content: center;
        background-color: #f4f4f4;
        position: relative;
        border-radius: 0.6vh;
        width: 50%;
        height: 40%;
        max-height: 45%;
        padding: 4% 8%;
    }

    .Hvac__HotWaterBtn {
        height: auto;
        background-color: #f4f4f4;
        border: 0px;
        outline: none;
        text-align: center;
        vertical-align: center;
        cursor: pointer;
    }

    .Hvac__BoostImg {
        width: auto;
        height: auto;
        margin-bottom: -0.8vw;
        min-height: 2vw;
        max-height: 4.5vw;
    }

    .Hvac__HotWaterBtn:hover .Hvac__BoostImg {
        width: auto;
        height: auto;
        margin-bottom: -0.8vw;
        min-height: 1vw;
        max-height: 5vw;
    }
}

@media (min-width: 500px) and (max-width: 768px) {
    .Hvac__title {
        font-size: 20px;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .Hvac__title {
        font-size: 24px;
    }
}

.Hvac__buttonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.third-row {
    display: flex;
    flex-direction: row;
    width: 90%;
    align-items: flex-end;
    justify-content: space-between;
    border-bottom: 1px solid #ededf5;
    padding: 20px 0;
}

.Hvac__heatCurveC {
    font-size: 2vh;
    margin-top: auto;
}

.Hvac__heatCurve {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
}

.Hvac__heatCurvebtnContainer {
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.Hvac__heatCurve {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.Hvac__Seprator {
    font-size: 16px;
    margin: 2px 15px;
}

.Hvac__heatCurvePlus,
.Hvac__heatCurveMinus {
    font-size: 12px;
    line-height: 15px;
    border: transparent;
    height: 25px;
    padding: 3px 8px 1px;
    border-radius: 30px;
    background-color: #2e2f30;
    color: #f4f4f4;
    outline: none;
    cursor: pointer;
}

.Hvac__heatCurvePlus:hover,
.Hvac__heatCurveMinus:hover {
    transform: scale(1.08);
    transition: transform 0.2s ease-in-out;
}

.third-row-title {
    font-size: 20px;
    display: flex;
}

@media (max-width: 500px) {
    .third-row-title,
    .Hvac__TemperatureC {
        font-size: 16px;
    }

    .Hvac__heatCurvePlus,
    .Hvac__heatCurveMinus {
        font-size: 10px;
        height: 20px;
    }

    .Hvac__Seprator {
        font-size: 14px;
        margin: 2px 8px;
    }
}
