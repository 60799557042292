.edit-container,
.cancel-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.popup-title {
    color: var(--text-color);
    font-size: 16px;
    border-bottom: 1px solid var(--border-color);
    width: 100%;
    padding-bottom: 5px;
}

.edit-name-field {
    width: 80%;
    font-size: 14px;
    border: none;
    border-bottom: 1px solid var(--orange);
    padding: 5px 10px;
}

.edit-name-field:focus {
    outline: none;
    border-bottom: 1.5px solid var(--orange);
}

.actions {
    display: flex;
    gap: 10px;
}

.actions .cancel-button,
.actions .ok-button {
    border: none;
    border-radius: 8px;
    padding: 5px 8px;
    min-width: 85px;
    background-color: var(--background-color);
    font-size: 12px;
    font-weight: 400;
    color: var(--white);
}

.actions .ok-button {
    background-color: var(--orange);
}

.actions .cancel-button:hover,
.actions .ok-button:hover {
    transform: scale(1.03);
}

@media (max-width: 500px) {
    .actions .cancel-button,
    .actions .ok-button {
        min-width: 60px;
        font-size: 10px;
    }

    .edit-name-field {
        width: 100%;
    }
}
