.img-wrapper {
    width: 100%;
    height: 80vh;
    justify-content: center;
    align-items: center;
    display: flex;
}

.img-wrapper img {
    width: 100%;
    object-fit: contain;
}
