.notification {
    width: 100%;
    height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 40px;
}

.notification i {
    font-size: 180px;
}

.notification i {
    color: #040404;
}

.notification h4,
.notification p {
    text-align: center;
    color: #040404;
}

.button-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.buttons {
    display: flex;
    justify-content: center;
    gap: 20px;
    width: 100%;
}

.buttons button {
    border: none;
    border-radius: 8px;
    color: white;
    padding: 8px 20px;
    width: 250px;
    background-color: #f67e00;
}

.buttons .cancel-button {
    background-color: black;
}
