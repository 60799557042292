.Savings {
    display: flex;
    justify-content: center;
    position: relative;
    width: 100%;
    border-radius: 20px;
    text-align: center;
    margin-bottom: 60px;
}

.gray-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(34, 34, 34, 0.842);
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
}

.gray-overlay span {
    color: white;
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    width: 100%;
    padding: 0 40px;
}

.Savings__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 12px;
    padding: 16px 12px;
    margin: 10px 0;
    width: 80%;
    box-shadow: 0px 0px 10px 1px #e3e1e1;
}

.Savings__container h3 {
    line-height: 1.2;
    margin-bottom: 20px;
}

.Saving__imageSection {
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    height: 45vh;
}

.Saving__imageContainer {
    display: flex;
    flex-direction: row;
}

.Savings__arrowImage {
    width: auto;
    height: 12vh;
    align-self: flex-end;
    margin-bottom: -1vh;
    margin-right: -2vh;
}

.Savings__shotImage {
    width: 20vh;
    height: 39vh;
}

.Savings__AddBtn {
    background-color: #ee801c;
    color: #ffffff;
    width: 4vh;
    height: 4vh;
    font-size: 3vh;
    font-family: 'fontAwesome';
    margin-top: 1.2vh;
    margin-left: 4.3vh;
    border: 0px;
    border-radius: 0.25vw;
    cursor: pointer;
}

.Savings__AddBtn a {
    text-decoration: none;
    color: #ffffff;
}

.Savings__AddBtn:hover {
    background-color: #efa866;
}

.Savings__newDevice {
    display: flex;
    flex-direction: column;
    width: 43vw;
    align-items: center;
}

.Savings__newDevice h3 {
    font-size: 3vh;
    margin: 0.5vw 0vw 0vw -0.3vw;
}

.Savings__newDevice p {
    white-space: normal;
    width: 40vh;
    margin-top: 0.8vw;
}

.Saving__addtextbtn {
    background-color: #ee801c;
    border: 0px;
    border-radius: 0.8vh;
    width: 35vw;
    height: 5vh;
    font-size: 2.5vh;
    color: #ffffff;
    cursor: pointer;
    margin-bottom: 3vh;
    white-space: nowrap;
}

.Saving__addtextbtn a {
    color: #ffffff;
    text-decoration: none;
}

.Saving__addtextbtn:hover {
    background-color: #efa866;
}

@media (max-width: 500px) {
    .Savings {
        display: flex;
        justify-content: center;
        position: relative;
        background-color: #ffffff;
        border: 0px;
        width: 90vw;
        height: 90vh;
        border-radius: 20px;
        text-align: center;
        font-size: 2vh;
        overflow: scroll;
        overflow-x: hidden;
    }

    .Savings::-webkit-scrollbar {
        width: 0;
        background: transparent;
    }

    .Savings__container h3 {
        font-size: 3vh;
        line-height: 1;
        margin-bottom: 15px;
    }

    .Saving__imageSection {
        display: flex;
        flex-direction: column;
        height: 55vh;
    }

    .Savings__arrowImage {
        width: auto;
        height: 10vh;
        align-self: flex-end;
        margin-bottom: -1vh;
        margin-right: -2.5vh;
    }

    .Savings__shotImage {
        width: auto;
        height: 50vh;
    }

    .Savings__AddBtn {
        background-color: #ee801c;
        color: #ffffff;
        width: 4vh;
        height: 4vh;
        font-size: 3vh;
        font-family: 'fontAwesome';
        margin-top: 0.9vh;
        margin-left: 4vh;
        border: 0px;
        border-radius: 0.5vh;
        cursor: pointer;
    }

    .Savings__AddBtn:hover {
        background-color: #efa866;
    }

    .Savings__newDevice h3 {
        font-size: 3vh;
        margin: 2vh 0vh 0vh -0.5vh;
    }

    .Savings__newDevice p {
        white-space: normal;
        font-size: 2vh;
        width: 32vh;
        margin-bottom: auto;
        margin-top: 2vh;
    }

    .Saving__addtextbtn {
        background-color: #ee801c;
        border: 0px;
        border-radius: 1.5vh;
        width: 32vh;
        height: 5.5vh;
        font-size: 2.5vh;
        color: #ffffff;
        cursor: pointer;
        margin-bottom: 3vh;
        margin-top: 3vh;
    }

    .Saving__addtextbtn a {
        color: #ffffff;
        text-decoration: none;
    }

    .Saving__addtextbtn:hover {
        background-color: #efa866;
    }
}
