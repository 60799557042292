.DeviceBase__smartCharging {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.DeviceBase__chargingTitle {
    color: #999999;
    white-space: nowrap;
    font-size: 18px;
    margin-bottom: 0.1vw;
    font-family: 'CalibriRegular';
}

@media (max-width: 500px) {
    .DeviceBase__chargingTitle {
        font-size: 14px;
    }
}

.DeviceBase__btnContainer {
    width: fit-content;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.DeviceBase__btnContainer input {
    display: none;
}

.DeviceBase__btnContainer .DeviceBase__btnLabel {
    box-sizing: content-box;
    width: 50px;
    height: 20px;
    padding: 4px;
    border-radius: var(--btn-mobile-w);
    background: var(--btn-bg-inactive);
    cursor: pointer;
    position: relative;
    transition: background 0.3s;
}

.DeviceBase__btnContainer .DeviceBase__btnLabel::before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    left: 4px;
    width: 19px;
    height: 19px;
    border-radius: 50%;
    background: var(--btn-thumb);
    transition: transform 0.3s;
}

.DeviceBase__btnContainer .DeviceBaseSmartCharging:checked + .DeviceBase__btnLabel::before {
    transform: translateX(28px);
    background: var(--btn-thumb-active);
}

.charge-schedule-row {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    overflow: hidden;
    padding: 20px 0;
    border-bottom: 1px solid #ededf5;
}

.charge-schedule-cs {
    width: 100%;
    padding: 0 0 20px;
    margin-bottom: 20px;
}

.charge-schedule {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    min-width: 420px;
}

@media (max-width: 500px) {
    .charge-schedule-row {
        flex-direction: column;
        gap: 12px;
        align-items: center;
    }

    .third-row.hvac-schedule {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        gap: 12px;
    }
}

.charge-schedule-description {
    font-size: 16px;
    max-width: 250px;
    text-align: end;
    margin: 0;
}

.charge-schedule-title {
    display: flex;
    flex-direction: column;
}

.charge-schedule-updated-at {
    font-weight: bold;
}

.genericDescription {
    font-size: 16px;
    margin: 0px;
}

@media (max-width: 500px) {
    .charge-schedule-title {
        font-weight: bold;
        align-items: center;
        text-align: center;
        width: 100%;
    }
    .charge-schedule-description {
        max-width: auto;
        text-align: justify;
    }
}
