@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap');

*,
*::before,
*::after {
    box-sizing: border-box;
}

/* variables */
:root {
    --orange: #f57e02;
    --gray: #474e56;
    --light-gray: #7e8389;
    --white: white;
    --black: black;
    --text-color: #242424;
    --border-color: #ebebeb;
    --font-family: 'Montserrat', sans-serif;
    --background-color: #b5b8bb;
}
