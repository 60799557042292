*,
::after,
::before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

:root {
    --btn-bg-inactive: #d4d0d0;
    --btn-bg-active: #ee801c;
    --border: #dcdcdc;
    --border-w: 1px;
    --btn-thumb: #2e2f30;
    --btn-thumb-active: #edf2f4;
    --btn-w: 3.5vw;
    /* change btn size, and everything else scales according to it. except border size */
    --btn-h: calc(var(--btn-w) / 2.8);
    /* increase btn height by descreasing the number */
    --gap: calc(var(--btn-h) / 6);
    /* increase gap by decreasing the number */

    /* For Mobile */
    --btn-mobile-w: 10vw;
    /* change btn size, and everything else scales according to it. except border size */
    --btn-mobile-h: calc(var(--btn-mobile-w) / 3);
    /* increase btn height by descreasing the number */
    --mobile-gap: calc(var(--btn-mobile-h) / 6);
    /* increase gap by decreasing the number */
    --toastify-z-index: 999999;
    --toastify-icon-color-success: #ee801c;
    --toastify-text-color-light: #141313;
}

.Toastify__toast-container {
    width: 43vw;
    align-items: center;
}

.Toastify__toast-theme--light {
    border-radius: 10px;
    background: #fbfbfb;
    color: #141313;
}

.MyDevices {
    display: flex;
    justify-content: center;
    position: relative;
    width: 100%;
    text-align: center;
    font-size: 2vh;
}

.MyDevices__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
}

.MyDevices__container h3 {
    line-height: 1.2;
    font-weight: 500;
    margin-bottom: 15px;
}

@media (max-width: 500px) {
    .MyDevices {
        display: flex;
        justify-content: center;
        position: relative;
        background-color: #ffffff;
        border: 0px;
        width: 90vw;
        border-radius: 20px;
        text-align: center;
        padding: 0;
    }

    .MyDevices::-webkit-scrollbar {
        width: 0;
        background: transparent;
    }

    :root {
        --toastify-z-index: 999999;
        --toastify-icon-color-success: #ee801c;
        --toastify-text-color-light: #141313;
    }

    .Toastify__toast-container {
        width: 84vw;
        align-items: center;
        margin-left: 8vw;
    }

    .Toastify__toast-theme--light {
        border-radius: 10px;
        background: #fbfbfb;
        color: #141313;
    }

    .MyDevices__container h3 {
        line-height: 1.3;
        font-weight: 500;
        margin-bottom: 10px;
        font-size: 3vh;
    }
}

/* ==================== Modal Css ================= */
@keyframes zoom-in {
    0% {
        transform: scale(0.5);
        opacity: 0;
        box-shadow: 0 0 0 rgba(241, 241, 241, 0);
    }

    1% {
        transform: scale(1);
        opacity: 0;
        box-shadow: 0 0 0 rgba(241, 241, 241, 0);
    }

    100% {
        transform: scale(1.5) translateY(0px);
        opacity: 1;
        box-shadow: 0 0 500px rgba(241, 241, 241, 0);
    }
}

.Saving__addtextbtn {
    background-color: #ee801c;
    font-size: 20px;
    border: 0px;
    border-radius: 0.8vh;
    width: auto;
    height: auto;
    color: #ffffff;
    padding: 5px 20px;
    cursor: pointer;
    white-space: nowrap;
    text-decoration: none;
}

.Saving__addtextbtn span {
    font-family: 'fontAwesome';
}

.Saving__addtextbtn a {
    color: #ffffff;
    text-decoration: none;
}

.Saving__addtextbtn:hover {
    background-color: #efa866;
}

@media (max-width: 500px) {
    .Saving__addtextbtn {
        background-color: #ee801c;
        border: 0px;
        border-radius: 1vh;
        width: auto;
        height: auto;
        color: #ffffff;
        cursor: pointer;
        margin: 10px 0px 10px 0px;
    }

    .Saving__addtextbtn span {
        font-family: 'fontAwesome';
    }

    .Saving__addtextbtn a {
        color: #ffffff;
        text-decoration: none;
    }

    .Saving__addtextbtn:hover {
        background-color: #efa866;
    }
}

/* badge indicator */

.badge-indicator {
    position: absolute;
    top: 10px;
    left: 10px;
    display: flex;
    align-items: center;
    font-size: 14px;
    text-transform: capitalize;
}

@media (min-width: 500px) {
    .badge-indicator {
        font-size: 16px;
    }
}

.badge-indicator .dot {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    display: block;
    margin-right: 8px;
}

.badge-indicator.lost .dot {
    background-color: red;
}

.badge-indicator.pending .dot {
    background-color: yellow;
}

.badge-indicator.active .dot {
    background-color: green;
}

.badge-indicator.inactive .dot {
    background-color: red;
}

.restore-btn .btn {
    padding: 5px 20px;
    border-radius: 8px;
    text-align: center;
    font-size: 16px;
    line-height: 18px;
    transition: opacity 0.2s ease-in-out;
}

.restore-btn .btn:hover {
    opacity: 0.7;
}

.MyDevices .device-state-lost {
    border-bottom: none;
    padding: 40px 0 15px;
}

.badge-indicator.unsupported .dot {
    background-color: #d5cfd0;
}

.devices__circle-container {
    width: 90px;
    height: 90px;
}

@media (min-width: 991px) {
    .devices__circle-container {
        width: 130px;
        height: 130px;
    }
}

.MyDevices__container .customer-number {
    min-width: 100%;
    font-size: 12px;
    color: rgb(134, 134, 134);
    margin: 0;
    font-weight: 700;
    display: flex;
    padding: 10px 0 25px;
    justify-content: flex-end;
}

.start-saving {
    display: flex;
    flex-direction: column;
    padding: 20px 0;
    width: 90%;
    height: auto;
    background-color: #ffffff;
    border-radius: 10px;
    align-items: center;
    box-shadow: 0px 0px 10px 1px #e3e1e1;
}

.start-saving .Saving__addtextbtn {
    margin-bottom: 0;
}

.edit-icon {
    display: inline-block;
    margin-left: 15px;
    font-size: 16px;
    padding-bottom: 5px;
    color: #888888;
    font-family: 'fontAwesome';
    cursor: pointer;
    transition: 0.4s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
}

.edit-icon:hover {
    color: #545454;
    transform: scale(1.1);
}

.devices-wrapper {
    width: 100%;
}
