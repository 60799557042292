.center {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
}

.img-styling {
    width: 140px;
}

.card {
    border: none !important;
}

.card-footer {
    background: none !important;
    padding: 14px !important;
}

.btn {
    border: none !important;
}

.btn:disabled {
    opacity: 0.8 !important;
}

.modal-content {
    border-radius: 16px !important;
}

.modal-header {
    padding: 16px 16px 0 0 !important;
    border-bottom: none !important;
}

.modal-body {
    padding-top: 0 !important;
}

.password {
    position: relative;
}

.password-viewer {
    z-index: 9999;
    position: absolute;
    top: 20%;
    right: 12px;
    background: none;
    border: none;
}

.black {
    color: #000;
}

.checkbox-wrapper {
    display: flex;
}

.error-message {
    color: crimson;
    margin-left: 10px;
}
