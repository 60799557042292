.SavingsWindow {
    display: flex;
    justify-content: center;
    position: relative;
    width: 100%;
    border-radius: 20px;
    text-align: center;
}

.SavingsWindow h3 {
    line-height: 1.2;
}

.SavingsWindow__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 25px 0 0;
}

.SavingsChartCard {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 620px;
    background-color: #ffffff;
    border-radius: 10px;
    align-items: center;
    padding: 0;
    margin-bottom: 25px;
    box-shadow: 0px 0px 10px 1px #e3e1e1;
}

.SavingsChart__Row {
    display: flex;
    flex-direction: column;
    min-height: 620px;
    align-items: center;
    width: 100%;
    padding: 10px 0;
}

.SavingsCard__Row {
    display: flex;
    flex-direction: row;
    width: 95%;
    height: auto;
    align-items: center;
    padding: 1vw 0vw 1vw 0vw;
}

.Savings__CostTitle {
    width: 95%;
    text-align: left;
    font-weight: 600;
    margin-top: 1vw;
}

.SavingsCost__Row {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    align-items: center;
    padding: 0 10px;
}

.Savings__livePriceContainer {
    width: 45%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Savings__SunnyContainer {
    width: 45%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Savings__SunnyContainer div:nth-child(1) {
    font-size: 14px;
    margin-bottom: 5%;
}

@media (min-width: 1200px) {
}

.Savings__verticalhr {
    border-left: 1px solid #e5e5e5;
    height: 150%;
}

.Savings__sunnyImg div {
    white-space: nowrap;
    margin-left: 6%;
    font-size: 14px;
}

.Savings__EvCost {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    color: #404040;
    padding: 5px 0 10px 0;
    border-bottom: 2px solid #ededf5;
}

.Savings__hr {
    width: 100%;
    height: 1px;
    margin-top: 1vw;
    margin-bottom: 1vw;
}

.Savings__hr hr {
    border-top: 2px solid #ededf5;
    border-right: 0px;
    border-bottom: 0px;
    border-left: 0px;
}

.Savings__HeatingCost {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    color: #404040;
    padding: 10px 0 5px 0;
}

.savings-icon {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    align-content: center;
    background-color: #f4f4f4;
    position: relative;
    border-radius: 3vw;
    width: 60px;
    height: 80%;
    padding: 1%;
    margin-right: 4%;
}

.savings-icon img {
    width: 30%;
}

@media (min-width: 768px) {
    .savings-icon {
        width: 100px;
        height: 100%;
    }
}

@media (min-width: 991px) {
    .savings-icon img {
        width: 36%;
    }
}

.Savings__EvInfo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    line-height: 1.5;
}

.SavingsCard .saving-card__title {
    font-size: 16px;
}

.SavingsCard .saving-card__kr-subtitle {
    font-size: 14px;
    font-weight: 700;
}

.SavingsCard .saving-card__tacker {
    font-size: 14px;
    color: #888888;
}

@media (min-width: 768px) {
    .SavingsCard .saving-card__title {
        font-size: 18px;
    }

    .SavingsCard .saving-card__kr-subtitle {
        font-size: 16px;
    }

    .SavingsCard .saving-card__tacker {
        font-size: 16px;
    }
}

@media (min-width: 991px) {
    .SavingsCard .saving-card__title {
        font-size: 22px;
    }

    .SavingsCard .saving-card__kr-subtitle {
        font-size: 18px;
    }

    .SavingsCard .saving-card__tacker {
        font-size: 17px;
    }
}

@media (min-width: 1200px) {
    .SavingsCard .saving-card__title {
        font-size: 24px;
    }
}

.Savings__HeatingInfo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    line-height: 1.5;
}

.Savings__stackChart {
    text-align: center;
    width: 100%;
    height: 50vh;
}

/* ======================= Circular Progress Bar css Started ======================== */

.Savings__ProgressBar {
    width: auto;
    height: auto;
    margin-left: auto;
    margin-right: 3%;
}

.Savings__ProgressBar .circle-background,
.Savings__ProgressBar .circle-progress {
    fill: none;
}

.Savings__ProgressBar .circle-background {
    stroke: #ddd;
}

.Savings__ProgressBar .circle-progress {
    stroke: #ee801c;
    stroke-linecap: round;
    stroke-linejoin: round;
}

/* ======================= Circular Progress Bar css ended ======================== */

@media (max-width: 500px) {
    .SavingsWindow::-webkit-scrollbar {
        width: 0;
        background: transparent;
    }

    .SavingsWindow__spotPrice {
        font-size: 3vw;
        margin-top: 10px;
        color: #999999;
    }

    .SavingsWindow h3 {
        line-height: 1.2;
        font-size: 3vh;
    }

    .SavingsChart__Row {
        display: flex;
        flex-direction: column;
        min-height: 650px;
        align-items: center;
        margin-top: 1.5vw;
    }

    .SavingsCost__Row {
        display: flex;
        flex-direction: column;
        width: 95%;
        height: auto;
        align-items: center;
        padding: 0;
        justify-content: space-between;
    }

    .SavingsLivePrice__Row {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: auto;
        align-items: center;
        padding: 2vw 0vw 2vw 0vw;
        margin-top: 1.5vw;
        justify-content: space-between;
    }

    .Savings__EvInfo {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        line-height: 1.3;
    }

    .Savings__HeatingInfo {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        line-height: 1.3;
    }

    /* ======================= Circular Progress Bar css Started ======================== */

    .Savings__ProgressBar {
        width: auto;
        height: auto;
        margin-left: auto;
        margin-right: 3%;
    }

    .Savings__ProgressBar .circle-background,
    .Savings__ProgressBar .circle-progress {
        fill: none;
    }

    .Savings__ProgressBar .circle-background {
        stroke: #ddd;
    }

    .Savings__ProgressBar .circle-progress {
        stroke: #ee801c;
        stroke-linecap: round;
        stroke-linejoin: round;
    }

    /* ======================= Circular Progress Bar css ended ======================== */

    .Savings__hr {
        width: 100%;
        height: 1px;
        margin-top: 2vw;
        margin-bottom: 2vw;
    }

    .Savings__hr hr {
        border-top: 2px solid #ededf5;
        border-right: 0px;
        border-bottom: 0px;
        border-left: 0px;
    }

    .Savings__livePriceContainer div:nth-child(1) {
        font-size: 4vw;
        margin-bottom: 5%;
    }

    .Savings__SunnyContainer div:nth-child(1) {
        font-size: 4vw;
        margin-bottom: 5%;
    }
}

.graph-button-group {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 15px;
    padding: 15px 15px 5px;
}

.graph-button {
    border: none;
    padding: 7px;
    border-radius: 10px;
    text-align: center;
    font-size: 16px;
    letter-spacing: 0.5px;
    line-height: 16px;
    font-weight: 550;
    width: 100px;
    transition: color, background-color, border, 0.2s ease-in-out;
}

.currency {
    display: flex;
    position: relative;
}

.currency::after {
    content: '';
    background-image: url('/public/assets/icons/down-chevron.svg');
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    top: 50%;
    left: 38px;
    transform: translateY(-50%);
    width: 14px;
    height: 14px;
}

@media (max-width: 768px) {
    .currency {
        width: 100%;
        justify-content: start;
        margin-bottom: 20px;
    }

    .currency::after {
        left: 50px;
    }
}

.graph-button-group .active,
.graph-button-group .graph-button:hover {
    opacity: 0.85;
    transform: scale(1.1);
}

.currency-select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    border: none;
    width: 58px;
    color: #ee801c;
    font-size: 18px;
    line-height: 18px;
    font-weight: bold;
    cursor: pointer;
}

@media (max-width: 768px) {
    .currency-select {
        margin-left: 15px;
    }
}

@media (max-width: 500px) {
    .currency-select {
        margin-left: 0;
    }

    .currency::after {
        left: 35px;
    }

    .graph-button-group {
        justify-content: center;
        gap: 10px;
    }

    .graph-button {
        font-size: 12px;
        height: auto;
        padding: 6px 20px;
        line-height: 1;
        width: 80px;
    }
}

.currency-select:focus,
.currency-select:active {
    outline: none;
    border: none;
    border-radius: 0;
    transform: none;
}

/* New css */
.boxes-row-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    margin: 0 -10px 20px;
}

.highliht-box {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.SavingsWindow .box-main-layout {
    background-color: white;
    box-shadow: 0px 0px 8px -1px #cccccc;
    border-radius: 12px;
    padding: 16px 12px;
}

.SavingsWindow .box-main-layout.box-main-layout--darken {
    box-shadow: 0px 0px 12px 2px #a3a3a3;
}

.SavingsWindow .box {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.SavingsWindow .box:first-child {
    margin-bottom: 20px;
}

.boxes-row-wrapper .box .icon.icon--progress-bar {
    position: relative;
    margin-bottom: 20px;
    overflow: hidden;
}

.boxes-row-wrapper .box .icon.icon--progress-bar .circle-info {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.boxes-row-wrapper .box .icon.icon--progress-bar .circle-info p {
    font-size: 24px;
    font-weight: 600;
    margin: 0;
    transform: translateX(150px);
    opacity: 0;
    animation: percenteInfo 3s;
    animation-delay: 2s;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
}

.boxes-row-wrapper .box .icon img {
    width: 32px;
    transform: translateX(-150px);
    opacity: 0;
    animation: circleInfoIcon 3s;
    animation-delay: 2s;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
}

.boxes-row-wrapper .box .box__info .title {
    font-size: 18px;
    line-height: 1.2;
    margin-bottom: 14px;
}

.boxes-row-wrapper .box .box__info .bold-text {
    font-size: 20px;
}

.boxes-row-wrapper .box .box__info .tacker {
    font-size: 14px;
    margin-bottom: 10px;
    color: #474e56;
}

.boxes-row-wrapper .box .box__info .saving-calc {
    font-size: 14px;
    margin-bottom: 0;
    color: black;
    margin-bottom: 8px;
}

.boxes-row-wrapper .box .box__info .saving-calc span {
    font-size: 18px;
    font-weight: 700;
    display: block;
}

.boxes-row-wrapper .box .box__progress-bar .circle-text {
    font-size: 14px;
}

.boxed--energy-weather .title {
    font-size: 16px;
    margin-bottom: 10px;
}

.boxed--energy-weather .img img {
    width: 40px;
    margin-bottom: 10px;
}

.savings-cost-title {
    font-size: 16px;
    margin-bottom: 6px;
}

@media (min-width: 500px) {
    .boxes-row-wrapper .box .box__info .title {
        min-height: 20px;
    }

    .boxes-row-wrapper .box:first-child {
        margin: 0 10px 0 0;
    }

    .boxes-row-wrapper .box:last-child {
        margin-left: 10px;
    }

    .boxes-row-wrapper {
        flex-direction: row;
    }
}

@media (min-width: 991px) {
    .boxes-row-wrapper .box .box__info .saving-calc {
        font-size: 16px;
        margin-bottom: 10px;
    }

    .boxes-row-wrapper .box .box__info .saving-calc span {
        font-size: 22px;
    }

    .boxes-row-wrapper .box {
        padding: 20px 16px;
    }

    .boxes-row-wrapper .box .box__progress-bar .circle-text {
        font-size: 18px;
    }

    .boxes-row-wrapper .box .icon img {
        width: 50px;
    }

    .boxes-row-wrapper .box .icon.icon--progress-bar .circle-info p {
        font-size: 28px;
    }

    .boxes-row-wrapper .box .box__info .title {
        font-size: 26px;
        margin-bottom: 24px;
    }

    .savings-cost-title {
        font-size: 20px;
        margin-bottom: 15px;
    }

    .boxed--energy-weather .title {
        font-size: 20px;
        margin-bottom: 20px;
    }
}

@media (min-width: 1200px) {
    .boxes-row-wrapper .box .icon.icon--progress-bar .circle-info p {
        font-size: 32px;
    }

    .boxes-row-wrapper .box .icon img {
        width: 55px;
    }
}

.intro-page {
    display: flex;
    margin-bottom: 20px;
}

.intro-page h3 {
    font-size: 28px;
    margin: 0;
    line-height: 1.2;
}

.customer-number {
    min-width: 100%;
    font-size: 12px;
    color: rgb(134, 134, 134);
    margin: 0;
    font-weight: 700;
    display: flex;
    padding-top: 10px;
    justify-content: flex-end;
}

@media (min-width: 991px) {
    .intro-page {
        margin-bottom: 30px;
    }

    .intro-page h3 {
        font-size: 36px;
    }
}

@media (min-width: 768px) {
    .intro-page p {
        font-size: 16px;
        padding: 0;
    }
}

/* Circle progress bar custom */
:root {
    --dot-diameter: 170px;
    --mobile-dot-diameter: 130px;
    --circle-border-width: 3px;
    --mobile-circle-border-width: 2px;
}

.savings__circle-container {
    width: var(--mobile-dot-diameter);
    height: var(--mobile-dot-diameter);
    opacity: 0;
    animation: progressBarRotate 3s;
    animation-delay: 400ms;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
}

@media (min-width: 991px) {
    .savings__circle-container {
        width: var(--dot-diameter);
        height: var(--dot-diameter);
    }

    .savings__circle-bg {
        stroke-width: var(--circle-border-width);
    }

    .savings__circle__progress {
        stroke-width: var(--circle-border-width);
    }
}

.fade-in {
    opacity: 0;
    animation: showBox 1s;
    animation-delay: 1.5s;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
}

@keyframes progressBarRotate {
    0% {
        transform: rotate(-90deg);
    }

    50% {
        transform: rotate(-0deg);
        opacity: 1;
    }

    100% {
        transform: rotate(-90deg);
        opacity: 1;
    }
}

@keyframes circleInfoIcon {
    0% {
        transform: translateX(-30px);
    }

    25% {
        transform: translateX(20px);
        opacity: 1;
    }

    50% {
        transform: translateX(-10px);
    }

    75% {
        transform: translateX(5px);
    }

    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes percenteInfo {
    0% {
        transform: translateX(30px);
    }

    25% {
        transform: translateX(-30px);
        opacity: 1;
    }

    50% {
        transform: translateX(10px);
    }

    75% {
        transform: translateX(-5px);
    }

    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes showBox {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.current-time-indicator {
    display: flex;
    align-items: center;
    column-gap: 5px;
    margin-top: 20px;
}

@media (max-width: 500px) {
    .current-time-indicator {
        margin: 20px 0 0 0;
    }
}

.current-time-indicator p {
    margin: 0;
    font-size: 13px;
    font-family: CalibriRegular;
}

.current-time-square {
    width: 26px;
    height: 13px;
}

.current-time-square rect {
    width: 26px;
    height: 13px;
}

@media (max-width: 320px) {
    .current-time-square rect {
        width: 22px;
        height: 11px;
    }
}

@media (max-width: 500px) {
    .current-time-square rect {
        width: 24px;
        height: 12px;
    }
}

.SavingsChartCard .date-range {
    padding: 20px 0;
}

.SavingsChartCard .date-range .goog-inline-block {
    width: 100%;
}

.SavingsChartCard .date-range .google-visualization-controls-slider-horizontal {
    width: 45%;
}

@media (max-width: 500px) {
    .SavingsChartCard .date-range .goog-inline-block {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .SavingsChartCard .date-range .google-visualization-controls-rangefilter-thumblabel {
        font-size: 12px;
        padding: 0 5px !important;
    }

    .SavingsChartCard .date-range .google-visualization-controls-slider-horizontal {
        width: 50%;
    }
}

@media (max-width: 320px) {
    .SavingsChartCard {
        height: 650px;
    }
}

.SavingsChartCard .date-range .google-visualization-controls-label {
    display: none;
}

.SavingsChartCard .date-range .google-visualization-controls-slider-horizontal {
    border: 1px solid var(--gray);
}

.SavingsChartCard .date-range .google-visualization-controls-slider-thumb {
    background-color: var(--gray);
    border: 1px solid var(--gray);
}

.SavingsChartCard .date-range .google-visualization-controls-slider-handle {
    background-color: var(--background-color);
}

.SavingsChartCard .date-range .google-visualization-controls-rangefilter-thumblabel {
    color: var(--text-color);
    font-weight: 600;
    letter-spacing: 0.7px;
    text-transform: uppercase;
    padding: 0 20px;
}
