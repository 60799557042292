.wrapper {
    width: 100%;
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.emulate-loader {
    display: inline-block;
    position: relative;
    width: 90px;
    height: 90px;
    transform: rotate(90deg);
    /* use this property for verctial, or delete for horizontal */
}

.emulate-loader div {
    display: inline-block;
    position: absolute;
    left: 8px;
    width: 11px;
    background: #fff;
    animation: emulate-loader 1.6s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}

.emulate-loader div:nth-child(1) {
    background-color: #ff925c;
    left: 8px;
    animation-delay: -0.24s;
}

.emulate-loader div:nth-child(2) {
    left: 32px;
    background-color: #f67e00;
    animation-delay: -0.12s;
}

.emulate-loader div:nth-child(3) {
    background-color: #474e56;
    left: 56px;
    animation-delay: 0;
}

@keyframes emulate-loader {
    0% {
        top: 8px;
        height: 90px;
    }

    50%,
    100% {
        top: 24px;
        height: 45px;
    }
}
