.Navbar {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-end;
    white-space: normal;
    max-width: 570px;
    width: 100%;
    margin: 0 auto;
}

@media (min-width: 500px) {
    .Navbar {
        padding: 40px 0 20px;
    }
}

.NavbarItem {
    color: #868686;
    margin: 0 15px;
    list-style-type: none;
}

.NavbarLink {
    color: #868686;
    text-decoration: none;
    display: block;
    transition: transform 0.3s ease-in-out;
    display: flex;
    align-items: center;
}

.NavbarLink:hover {
    color: #000000;
}

@media (min-width: 500px) {
    .NavbarLink {
        font-size: 12px;
    }

    .NavbarLink:hover {
        transform: scale(1.15);
    }
}

@media (min-width: 768px) {
    .NavbarLink {
        font-size: 14px;
    }
}

@media (min-width: 1200px) {
    .NavbarLink {
        font-size: 18px;
    }

    .NavbarItem {
        margin: 0 20px;
    }
}

.NavbarLinkActive {
    color: #000000;
    transform: scale(1.15);
}

@media (max-width: 500px) {
    .Navbar {
        display: flex;
        flex-direction: row;
        width: 95vw;
        justify-content: space-evenly;
        align-items: center;
        gap: 15px;
        padding: 20px 5px;
        border-radius: 8px 8px 0 0;
    }

    .NavbarItem {
        margin: 0 5px;
    }

    .NavbarLinkActive {
        text-align: center;
        color: #ffffff;
        font-size: 12px;
    }

    .mobileNav span {
        color: #ffffff;
        font-weight: 400;
        font-size: 12px;
    }

    .language {
        padding: 0 !important;
    }

    .mobileNavImg {
        margin: 0 !important;
    }
}

.mobileNavImg {
    width: auto;
    height: auto;
    min-height: 10px;
    max-height: 20px;
    margin-right: 10px;
    filter: contrast(400%);
}

.desktop-nav,
.mobileNav {
    display: flex;
    flex-direction: column;
    gap: 5px;
    justify-content: center;
    align-items: center;
}

.desktop-nav .mobileNavImg {
    margin-right: 0;
}

.language {
    background-color: transparent;
    border: none;
    padding-bottom: 2.5px;
}

@media (min-width: 920px) {
    .Navbar {
        min-width: 810px;
    }
}
