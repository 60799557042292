@font-face {
    font-family: CalibriRegular;
    src: url('../src/assets/fonts/CalibriRegular.ttf') format('opentype');
}

@font-face {
    font-family: CalibriBold;
    src: url('../src/assets/fonts/CalibriBold.TTF') format('opentype');
}

#root {
    margin: 0;
    font-family: 'CalibriRegular';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.bold-text {
    font-family: 'CalibriBold';
}
