.notification {
    width: 100%;
    height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 40px;
}

.notification i {
    font-size: 180px;
}

.notification i {
    color: #040404;
}

.notification h4,
.notification p {
    text-align: center;
    color: #040404;
}

.image {
    width: 100%;
    height: 240px;
    margin-bottom: 6px;
    object-fit: contain;
}
